import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  mainPurple,
  mainBlack,
  transHover,
  mainWhite,
  screen,
} from "../variables";
import { isOdd } from "../../helpers";
import ArrowPlain from "../../images/svg/arrow-plain.svg";

const Wrapper = styled.section`
  .slick-slider {
    .slick-list {
      padding: 60px 0;

      .slick-slide {
        div {
          outline: none;

          .each-slide-flex {
            display: flex !important;
            align-items: center;

            .col {
              &--img {
                display: none;
                @media ${screen.xxsmall} {
                  display: block;
                  width: 46%;
                  padding: 0 80px 0 35px;
                }
                @media ${screen.small} {
                  width: 38%;
                  padding: 0 113px 0 52px;
                }
              }

              .img-wrapper {
                position: relative;
                transform: rotate(-10deg);

                img {
                  z-index: 3;
                  border: 6px ${mainWhite} solid;
                  @media ${screen.xxsmall} {
                    border: 22px ${mainWhite} solid;
                  }
                }

                &::before {
                  content: "";
                  display: block;
                  background: #e2e2e2;
                  position: absolute;
                  width: calc(100% + 6px);
                  height: calc(100% + 6px);
                  bottom: -6px;
                  left: -6px;
                  z-index: 2;
                  @media ${screen.xxsmall} {
                    width: calc(100% + 12px);
                    height: calc(100% + 12px);
                    bottom: -12px;
                    left: -12px;
                  }
                }

                &::after {
                  content: "";
                  background: #20a9c3;
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  bottom: -10px;
                  right: -4px;
                  z-index: 1;
                  @media ${screen.xxsmall} {
                    bottom: -24px;
                    right: -12px;
                  }
                }

                &--odd {
                  transform: rotate(10deg);
                }

                &--mobile-only {
                  min-width: 100px;
                  margin: 0 12px 0 0;
                  @media ${screen.xxsmall} {
                    display: none;
                    margin: 0;
                  }
                }
              }

              &--text {
                @media ${screen.xxsmall} {
                  width: 54%;
                }
                @media ${screen.small} {
                  width: 62%;
                }

                .heading {
                  font-weight: 900;
                  line-height: 1.22;
                  position: relative;
                  font-size: 1.56rem;
                  @media ${screen.xxsmall} {
                    font-size: 3rem;
                  }
                  @media ${screen.small} {
                    font-size: 3rem;
                    line-height: 1.19;
                  }
                }

                .description {
                  margin: 16px 0 0 0;
                  @media ${screen.xxsmall} {
                    margin: 23px 0 0 0;
                  }

                  p {
                    margin: 0 0 21px 0;
                    font-size: 1.02rem;
                    @media ${screen.small} {
                      font-size: 1.06rem;
                    }
                    &:last-child {
                      margin: 0;
                    }
                  }
                }

                .owner {
                  display: flex;
                  align-items: flex-start;
                  margin: 25px 0 0 0;
                  padding: 0 0 0 16px;
                  @media ${screen.xxsmall} {
                    padding: 0;
                    align-items: flex-end;
                  }

                  .name {
                    color: ${mainPurple};
                    font-size: 0.82rem;
                    font-weight: 900;
                    letter-spacing: 2px;
                    margin: 18px 0 0 13px;
                    max-width: 250px;
                    line-height: 1.55;
                    text-transform: uppercase;
                    @media ${screen.xxsmall} {
                      margin: 4px 0 0 0;
                      font-size: 1.06rem;
                      max-width: none;
                      line-height: 1;
                    }
                    @media ${screen.small} {
                      font-size: 1.1rem;
                    }
                  }
                }

                .arrow-btns {
                  position: relative;
                  z-index: 9;
                  margin: 65px 0 0 0;
                  display: flex;
                  justify-content: center;
                  @media ${screen.xxsmall} {
                    margin: 45px 0 0 0;
                    display: block;
                  }

                  &__btn {
                    all: unset;
                    cursor: pointer;

                    svg {
                      width: 38px;

                      .arrow-plain-inner {
                        fill: ${mainBlack};
                        transition: ${transHover};
                      }
                    }

                    &--prev {
                      margin: 0 25px 0 0;
                      svg {
                        transform: scaleX(-1);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .slick-dots {
      bottom: -5px;
      right: 0;
      text-align: center;
      @media ${screen.xxsmall} {
        text-align: right;
        bottom: 30px;
      }

      li {
        width: 15px;
        height: 15px;
        background: none;
        border-radius: 50%;
        border: 2px ${mainBlack} solid;
        margin: 0 10px;
        @media ${screen.xxsmall} {
          width: 12px;
          height: 12px;
          margin: 0 0 0 12px;
        }

        button {
          width: 14px;
          height: 14px;

          &::before {
            display: none;
          }
        }
      }

      .slick-active {
        background: ${mainBlack};
      }
    }
  }
`;

const TestimonialCarousel = ({ testimonial_list, noDots }) => {
  const settings = {
    arrows: false,
    dots: false,
    draggable: false,
    fade: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let slider;

  const next = () => {
    if (slider) {
      slider.slickNext();
    }
  };

  const prev = () => {
    if (slider) {
      slider.slickPrev();
    }
  };

  return (
    <Wrapper>
      <Slider ref={(c) => (slider = c)} {...settings}>
        {testimonial_list.map((item, i) => (
          <div className="each-slide-flex" key={i + item.from_name}>
            <div className="col col--img">
              <div
                className={
                  isOdd(i) ? "img-wrapper img-wrapper--odd" : "img-wrapper"
                }
              >
                <GatsbyImage
                  image={item.featured_image.thumbnails.cropped.gatsbyImageData}
                  alt={item.featured_image.alt || "Featured Dog"}
                />
              </div>
            </div>
            <div className="col col--text">
              <h3 className="heading">Don’t just take our woof for it…</h3>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: item.message.html }}
              />
              <h4 className="owner">
                <div
                  className={
                    isOdd(i)
                      ? "img-wrapper img-wrapper--mobile-only img-wrapper--odd"
                      : "img-wrapper img-wrapper--mobile-only"
                  }
                >
                  <GatsbyImage
                    image={
                      item.featured_image.thumbnails.cropped.gatsbyImageData
                    }
                    alt={item.featured_image.alt || "Featured Dog"}
                  />
                </div>

                <span className="name">{item.from_name}</span>
              </h4>

              {!noDots && (
                <div className="arrow-btns">
                  <motion.button
                    className="arrow-btns__btn arrow-btns__btn--prev"
                    whileTap={{ scale: 0.935 }}
                    onClick={prev}
                  >
                    <ArrowPlain />
                  </motion.button>
                  <motion.button
                    className="arrow-btns__btn arrow-btns__btn--next"
                    whileTap={{ scale: 0.935 }}
                    onClick={next}
                  >
                    <ArrowPlain />
                  </motion.button>
                </div>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </Wrapper>
  );
};

export default TestimonialCarousel;
